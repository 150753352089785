
.work-bench-right {
  width: 360px;
  background: #fff;
  margin-left: 25px;
}

.bench-nodata-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
}

.bench-nodata-wrapper .bench-nodata-img {
  width: 200px;
}

.bench-nodata-wrapper .bench-nodata-img img {
  max-width: 100%;
}

.bench-nodata-wrapper .bench-nodata-text {
  margin: 30px 0 40px;
  color: #666;
  font-size: 14px;
}

.bench-content-wrapper {
  padding: 15px;
  position: relative;
}

.bench-content-wrapper .bench-head {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.bench-content-wrapper .bench-head-avatar {
  width: 80px;
  height: 80px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bench-content-wrapper .bench-head-avatar img {
  max-width: 100%;
  max-height: 100%;
}

.bench-content-wrapper .bench-head-info {
  width: 1%;
  flex: 1;
  line-height: 1;
  margin-left: 15px;
  color: #999;
  font-size: 14px;
}

.bench-content-wrapper .bench-head-info .name {
  color: #333;
  font-size: 16px;
}

.bench-content-wrapper .bench-head-info .name:hover {
  color: #20c997;
}

.bench-content-wrapper .bench-head-info .account {
  margin-top: 10px;
}

.bench-content-wrapper .bench-head-info .info {
  margin-top: 10px;
}

.bench-content-wrapper .bench-head-info .info span {
  display: inline-block;
  margin-right: 6px;
}

.bench-content-wrapper .bench-head-info .info span:last-of-type {
  margin-right: 0;
}

.bench-content-wrapper .bench-summary {
  margin-top: 20px;
  display: flex;
}

.bench-content-wrapper .bench-summary .summary-content {
  width: 1%;
  flex: 1;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.bench-content-wrapper .bench-ranking {
  margin-top: 20px;
}

.bench-content-wrapper .bench-ranking .ranking-item {
  display: inline-flex;
  align-items: center;
  background: #ff1616;
  color: #fff;
  height: 24px;
  line-height: 24px;
  border-radius: 12px;
  padding: 0 15px 0 2px;
  margin-bottom: 10px;
}

.bench-content-wrapper .bench-ranking .ranking-item:last-of-type {
  margin-bottom: 0;
  background: #ffb624;
}

.bench-content-wrapper .bench-ranking .ranking-item .rank-icon {
  width: 20px;
  height: 20px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  overflow: hidden;
  background: #fff url("../../assets/img/image/wb-hg-icon.png") no-repeat center;
}

.bench-content-wrapper .bench-ranking .ranking-item .text {
  margin-left: 10px;
}

.bench-content-wrapper .bench-ranking .ranking-item .text .num {
  margin: 0 4px;
}

.bench-content-wrapper .bench-data-overview {
  margin-top: 40px;
}

.bench-content-wrapper .bench-data-overview .data-title {
  font-size: 16px;
}

.bench-content-wrapper .bench-data-overview .manualRefresh{
    position: absolute;
    right: 0;
    top: -25px;
    cursor: pointer;
    
}

.bench-content-wrapper .bench-data-overview .update-data {
  position: absolute;
  top: 44.5%;
  right: 4%;
  color: #999;
}

.bench-content-wrapper .data-list {
  /*font-size: 12px;*/
  color: #999;
}

.bench-content-wrapper .data-list .data-item {
  display: flex;
  flex-wrap: wrap;
}

.bench-content-wrapper .data-list .item-left {
  width: 35%;
  margin-top: 15px;
  flex: 1;
}
.bench-content-wrapper .data-list .item-center {
  width: 35%;
  margin-top: 15px;
  margin-left: 20px;
  flex: 1;
  .setbackground-color{
      display: inline-block;
      width: 20px;
      height: 20px;
      text-align: center;
      border-radius: 3px;
  }
}

.bench-content-wrapper .data-list .item-right {
  width: 23%;
  margin-top: 15px;
  text-align: right;
}

.bench-content-wrapper .data-list .item-share {
  position: absolute;
  top: 93%;
}
.bench-content-wrapper .data-list .item-comments {
  position: absolute;
  bottom: 10%;
  right: 3%;
}

.bench-content-wrapper .data-list .item-num {
  font-size: 16px;
  color: #333;
}

.big-green {
  color: #20c997;
}

.bench-content-wrapper .data-list .data-item:first-of-type {
  padding-bottom: 15px;
  border-bottom: 1px solid #ededed;
}

.bench-content-wrapper .data-list .data-item:first-of-type .item-num {
  font-size: 20px;
}

.bench-content-wrapper .bench-data-oper {
  margin-top: 40px;
  text-align: center;
}
